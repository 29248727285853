export const ROUTES = {
  HOME: '/',
  ONBOARDING_BASIC_INFO: '/onboarding/basic-info',
  ONBOARDING_SHIPPING_ADDRESS: '/onboarding/shipping-address',
  ONBOARDING_SHIPPING_TERMS: '/onboarding/shipping-terms',
  ACCESS: '/access',
  SHIPPING_LABELS: '/shipping-labels',
  NEW_SHIPPING_LABEL: '/shipping-labels/new',
  PAYMENT_DETAILS_SUCCESS: '/payment-details/success',
  PAYMENT_HISTORY: '/payments/history',
  CUSTOMERS: '/customers',
  CRM_INTRO: '/customer-engagement/intro',
  CUSTOMER_DATA: '/customer-engagement/data',
  LIVE_LINEUP_INTRO: '/lineups/intro/',
  SHIPPING_INTRO: '/shipping/intro',
  SHIPPING_WALLET: '/shipping/wallet',
  LINEUPS: '/lineups/:lineupId',
  LOYALTY_PROGRAM: '/customers/loyalty-program',
  CUSTOMER_CARD_DATA: '/customers/extra-data-fields',
  LINEUP_OVERVIEW: '/lineups/overview',
  LINEUP_MINE: '/lineups/mine',
  EMAIL_MARKETING: '/marketing/email',
  SETTINGS: '/settings',
};
