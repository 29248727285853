import Axios, { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { config as appConfig } from '@/config';

if (!appConfig.API_BASE_URL) {
  throw new Error(
    'API_BASE_URL is not defined. Please set REACT_APP_API_BASE_URL environment variable.'
  );
}

export const AXIOS_INSTANCE = Axios.create({
  baseURL: appConfig.API_BASE_URL,
  headers: {
    ...(appConfig.ENVIRONMENT === 'development' && {
      'ngrok-skip-browser-warning': '69400',
    }),
  },
});

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const subscribeTokenRefresh = (cb: (token: string) => void) => {
  refreshSubscribers.push(cb);
};

const onTokenRefreshed = (token: string) => {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
};

const getAccessToken = () => localStorage.getItem('accessToken');
const getRefreshToken = () => localStorage.getItem('refreshToken');
const setTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};
const removeTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export const refreshTokens = async (): Promise<{ accessToken: string; refreshToken: string }> => {
  const refreshToken = getRefreshToken();

  console.log('refreshing tokens 2');

  if (!refreshToken) {
    console.log('no refresh token avaialble');
    throw new Error('No refresh token available');
  }

  console.log('trying');
  try {
    const response = await AXIOS_INSTANCE.post('/tokens/refresh', { refresh: refreshToken });
    console.log('tried');
    console.log(response);

    if (!response.data.access || !response.data.refresh) {
      console.log('big error');
      throw new Error('Invalid response from refresh token endpoint');
    }

    return {
      accessToken: response.data.access,
      refreshToken: response.data.refresh,
    };
  } catch (error) {
    console.log('error');
    if (Axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        // Refresh token is invalid or expired
        throw new Error('Refresh token expired');
      } else {
        // Other API errors
        throw new Error(
          `Failed to refresh tokens: ${error.response?.data?.message || error.message}`
        );
      }
    } else {
      // Non-Axios errors
      console.error('Failed to refresh tokens:', error);
      throw new Error('An unexpected error occurred while refreshing tokens');
    }
  }
};

AXIOS_INSTANCE.interceptors.request.use((axiosConfig: InternalAxiosRequestConfig) => {
  const token = getAccessToken();
  if (token) {
    axiosConfig.headers.Authorization = `Bearer ${token}`;
  }
  return axiosConfig;
});

AXIOS_INSTANCE.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };

    // Check if it's a 401 error and not a refresh token request
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== '/tokens/refresh'
    ) {
      if (isRefreshing) {
        try {
          const token = await new Promise<string>((resolve) => {
            subscribeTokenRefresh((newToken: string) => {
              resolve(newToken);
            });
          });
          if (originalRequest.headers) {
            originalRequest.headers.Authorization = `Bearer ${token}`;
          }
          return await Axios(originalRequest);
        } catch (subscribeError) {
          return Promise.reject(subscribeError);
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const { accessToken, refreshToken } = await refreshTokens();
        setTokens(accessToken, refreshToken);
        onTokenRefreshed(accessToken);
        if (originalRequest.headers) {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        }
        return await Axios(originalRequest);
      } catch (refreshError) {
        // Handle refresh token failure
        removeTokens();
        // Notify app about logout
        // You might want to redirect to login page or dispatch a logout action here
        return await Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    // If it's a 401 from the refresh token endpoint, or any other error, reject the promise
    return Promise.reject(error);
  }
);

export const customInstance = <T>(customConfig: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...customConfig, cancelToken: source.token }).then(
    ({ data }) => data
  );
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by Vue Query');
  };
  return promise;
};

export const logout = async (): Promise<void> => {
  // Clear tokens from localStorage
  removeTokens();

  // Communicate logout to Chrome extension
  if (window.chrome && chrome.runtime && chrome.runtime.sendMessage) {
    try {
      await new Promise<void>((resolve, reject) => {
        chrome.runtime.sendMessage(appConfig.CHROME_EXTENSION_ID, { type: 'LOGOUT' }, () => {
          if (chrome.runtime.lastError) {
            reject(chrome.runtime.lastError);
          } else {
            resolve();
          }
        });
      });
    } catch (error) {
      console.error('Failed to communicate logout to extension:', error);
    }
  } else {
    console.log('Chrome runtime or sendMessage function not available');
  }

  // Reset Axios instance
  AXIOS_INSTANCE.interceptors.request.use((axiosConfig: InternalAxiosRequestConfig) => {
    delete axiosConfig.headers.Authorization;
    return axiosConfig;
  });

  // You might want to clear other app state here, e.g., user info in your auth context
  // clearUserInfo();

  // Optionally, redirect to login page
  window.location.href = '/access';
};

export default customInstance;
export interface ErrorType<Error> extends AxiosError<Error> {}
