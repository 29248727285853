import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { ROUTES } from './constants';
import { ProtectedRoute } from './ProtectedRoute';
import { MixpanelPageTracker } from '@/components/organisms/MixpanelPageTracker';
import CustomerData from './pages/CustomerData';

// Lazily load all components
const HomePage = lazy(() => import('./pages/Home.page'));
const AccessComponent = lazy(() => import('./pages/Access.page'));
const OnboardingBasicInfoPage = lazy(() => import('./pages/onboarding/BasicInfo.page'));
const OnboardingShippingAddressPage = lazy(() => import('./pages/onboarding/ShippingAddress.page'));
const OnboardingShippingTermsPage = lazy(() => import('./pages/onboarding/ShippingTerms.page'));
const DashboardTemplate = lazy(() => import('@/Templates/Dashboard'));
const PageTemplate = lazy(() => import('@/Templates/Page'));
const ShippingLabels = lazy(() => import('@/pages/ShippingLabels'));
const NewShippingLabel = lazy(() => import('@/pages/newShippingLabel/NewShippingLabel'));
const PaymentDetailsSuccess = lazy(() => import('./pages/PaymentDetailsSuccess'));
const PaymentHistory = lazy(() => import('./pages/PaymentHistory'));
const Customers = lazy(() => import('./pages/Customers'));
const Lineups = lazy(() => import('./pages/Lineups.page'));
const LoyaltyProgram = lazy(() => import('./pages/LoyaltyProgram'));
const CustomerCardData = lazy(() => import('./pages/CustomerCardData'));
const EmailMarketing = lazy(() => import('./pages/EmailMarketing'));
const LineupOverview = lazy(() => import('./pages/LineupOverview'));
const CustomerEngagementOverview = lazy(() => import('./pages/CustomerEngagementOverview.page'));
const LiveLineupIntro = lazy(() => import('./pages/LiveLineupIntro'));
const ShippingIntro = lazy(() => import('./pages/ShippingIntro'));
const ShippingWallet = lazy(() => import('./pages/ShippingWallet'));
const Settings = lazy(() => import('./pages/Settings'));

const SuspenseWrapper = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<div>Loading resources...</div>}>{children}</Suspense>
);

const RouteWrapper = () => (
  <>
    <MixpanelPageTracker />
    <Outlet />
  </>
);

const router = createBrowserRouter([
  {
    element: <RouteWrapper />,
    children: [
      {
        path: ROUTES.HOME,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <HomePage />
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.SETTINGS,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <Settings />
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.SHIPPING_LABELS,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <PageTemplate
                  heading="Shipping Labels"
                  description="View and manage all the shipping labels you have generated"
                >
                  <ShippingLabels />
                </PageTemplate>
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.NEW_SHIPPING_LABEL,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <PageTemplate
                  heading="New Shipping Label"
                  description="Enter address and parcel information to get a shipping label"
                >
                  <NewShippingLabel />
                </PageTemplate>
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.ACCESS,
        element: (
          <SuspenseWrapper>
            <AccessComponent />
          </SuspenseWrapper>
        ),
      },
      {
        path: ROUTES.ONBOARDING_BASIC_INFO,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <OnboardingBasicInfoPage />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
      },
      {
        path: ROUTES.ONBOARDING_SHIPPING_ADDRESS,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <OnboardingShippingAddressPage />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
      },
      {
        path: ROUTES.ONBOARDING_SHIPPING_TERMS,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <OnboardingShippingTermsPage />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
      },
      {
        path: ROUTES.PAYMENT_DETAILS_SUCCESS,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <PaymentDetailsSuccess />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
      },
      {
        path: ROUTES.SHIPPING_WALLET,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <PageTemplate
                  heading="Shipping Wallet"
                  description="Manage your shipping wallet from one place"
                >
                  <ShippingWallet />
                </PageTemplate>
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.PAYMENT_HISTORY,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <PageTemplate
                  heading="Wallet History"
                  description="View all the payment activity occurred on your wallet"
                >
                  <PaymentHistory />
                </PageTemplate>
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.CUSTOMERS,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <PageTemplate
                  heading="Customers"
                  description="View all the customers and their loyalty points"
                >
                  <Customers />
                </PageTemplate>
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.CUSTOMER_DATA,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <PageTemplate
                  heading="Customers"
                  description="View all the customers and their loyalty points"
                >
                  <CustomerData />
                </PageTemplate>
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.SHIPPING_INTRO,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <ShippingIntro />
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.CRM_INTRO,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <CustomerEngagementOverview />
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.LIVE_LINEUP_INTRO,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <LiveLineupIntro />
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.LOYALTY_PROGRAM,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <PageTemplate
                  heading="Loyalty Program"
                  description="Preview and manage all the customer loyalty wallets"
                >
                  <LoyaltyProgram />
                </PageTemplate>
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.CUSTOMER_CARD_DATA,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <PageTemplate
                  heading="Customer Data Fields"
                  description="Manage all the data fields you have created, update their options or add a new field."
                >
                  <CustomerCardData />
                </PageTemplate>
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.EMAIL_MARKETING,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <PageTemplate
                  heading="Email Marketing"
                  description="Create and send marketing emails to your customers"
                >
                  <EmailMarketing />
                </PageTemplate>
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.LINEUP_OVERVIEW,
        element: (
          <SuspenseWrapper>
            <ProtectedRoute>
              <DashboardTemplate />
            </ProtectedRoute>
          </SuspenseWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <PageTemplate heading="Lineup Overview (soon)" description="Soon">
                  <LineupOverview />
                </PageTemplate>
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: ROUTES.LINEUPS,
        element: (
          <SuspenseWrapper>
            <Lineups />
          </SuspenseWrapper>
        ),
      },
    ],
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
