/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Your Project API
 * Your project description
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Balance,
  CustomerPortalLink,
  PatchedUser,
  PaymentDetailsResponse,
  User
} from '.././models'
import { customInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




export const usersCustomerPortalCreate = (
    
 ) => {
      
      
      return customInstance<CustomerPortalLink>(
      {url: `/users/customer-portal/`, method: 'POST'
    },
      );
    }
  


export const getUsersCustomerPortalCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersCustomerPortalCreate>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof usersCustomerPortalCreate>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersCustomerPortalCreate>>, void> = () => {
          

          return  usersCustomerPortalCreate()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UsersCustomerPortalCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersCustomerPortalCreate>>>
    
    export type UsersCustomerPortalCreateMutationError = ErrorType<unknown>

    export const useUsersCustomerPortalCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersCustomerPortalCreate>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof usersCustomerPortalCreate>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getUsersCustomerPortalCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * This endpoint is used to retrieve and update the current user's information.

It first retrieves the user model and then gets the user with the corresponding firebase_id from the request.

The user's data is then serialized using the UserSerializer and returned in a JsonResponse.
 */
export const usersMeRetrieve = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<User>(
      {url: `/users/me`, method: 'GET', signal
    },
      );
    }
  

export const getUsersMeRetrieveQueryKey = () => {
    return [`/users/me`] as const;
    }

    
export const getUsersMeRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof usersMeRetrieve>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usersMeRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersMeRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersMeRetrieve>>> = ({ signal }) => usersMeRetrieve(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof usersMeRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type UsersMeRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersMeRetrieve>>>
export type UsersMeRetrieveQueryError = ErrorType<unknown>

export const useUsersMeRetrieve = <TData = Awaited<ReturnType<typeof usersMeRetrieve>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usersMeRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUsersMeRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * This endpoint is used to update the current user's information.

It first retrieves the user model and then gets the user with the corresponding firebase_id from the request.

The user's data is then updated with the provided data and serialized using the UserSerializer and returned in a JsonResponse.
 */
export const usersMePartialUpdate = (
    patchedUser: NonReadonly<PatchedUser>,
 ) => {
      
      
      return customInstance<User>(
      {url: `/users/me`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedUser
    },
      );
    }
  


export const getUsersMePartialUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMePartialUpdate>>, TError,{data: NonReadonly<PatchedUser>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof usersMePartialUpdate>>, TError,{data: NonReadonly<PatchedUser>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMePartialUpdate>>, {data: NonReadonly<PatchedUser>}> = (props) => {
          const {data} = props ?? {};

          return  usersMePartialUpdate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UsersMePartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof usersMePartialUpdate>>>
    export type UsersMePartialUpdateMutationBody = NonReadonly<PatchedUser>
    export type UsersMePartialUpdateMutationError = ErrorType<unknown>

    export const useUsersMePartialUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMePartialUpdate>>, TError,{data: NonReadonly<PatchedUser>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof usersMePartialUpdate>>,
        TError,
        {data: NonReadonly<PatchedUser>},
        TContext
      > => {

      const mutationOptions = getUsersMePartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * GET: Retrieve the current user's Stripe balance.
POST: Add funds to the user's Stripe balance.
 */
export const usersMeBalanceRetrieve = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<Balance>(
      {url: `/users/me/balance`, method: 'GET', signal
    },
      );
    }
  

export const getUsersMeBalanceRetrieveQueryKey = () => {
    return [`/users/me/balance`] as const;
    }

    
export const getUsersMeBalanceRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof usersMeBalanceRetrieve>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usersMeBalanceRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersMeBalanceRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersMeBalanceRetrieve>>> = ({ signal }) => usersMeBalanceRetrieve(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof usersMeBalanceRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type UsersMeBalanceRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersMeBalanceRetrieve>>>
export type UsersMeBalanceRetrieveQueryError = ErrorType<unknown>

export const useUsersMeBalanceRetrieve = <TData = Awaited<ReturnType<typeof usersMeBalanceRetrieve>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usersMeBalanceRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUsersMeBalanceRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * GET: Retrieve the current user's Stripe balance.
POST: Add funds to the user's Stripe balance.
 */
export const usersMeBalanceCreate = (
    
 ) => {
      
      
      return customInstance<void>(
      {url: `/users/me/balance`, method: 'POST'
    },
      );
    }
  


export const getUsersMeBalanceCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMeBalanceCreate>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof usersMeBalanceCreate>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMeBalanceCreate>>, void> = () => {
          

          return  usersMeBalanceCreate()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UsersMeBalanceCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersMeBalanceCreate>>>
    
    export type UsersMeBalanceCreateMutationError = ErrorType<unknown>

    export const useUsersMeBalanceCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMeBalanceCreate>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof usersMeBalanceCreate>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getUsersMeBalanceCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Retrieve the payment details associated with the user's account.
This includes the status of the payment method and basic card details if available.
 */
export const usersMePaymentDetailsRetrieve = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<PaymentDetailsResponse>(
      {url: `/users/me/payment-details`, method: 'GET', signal
    },
      );
    }
  

export const getUsersMePaymentDetailsRetrieveQueryKey = () => {
    return [`/users/me/payment-details`] as const;
    }

    
export const getUsersMePaymentDetailsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof usersMePaymentDetailsRetrieve>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usersMePaymentDetailsRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersMePaymentDetailsRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersMePaymentDetailsRetrieve>>> = ({ signal }) => usersMePaymentDetailsRetrieve(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof usersMePaymentDetailsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type UsersMePaymentDetailsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersMePaymentDetailsRetrieve>>>
export type UsersMePaymentDetailsRetrieveQueryError = ErrorType<unknown>

export const useUsersMePaymentDetailsRetrieve = <TData = Awaited<ReturnType<typeof usersMePaymentDetailsRetrieve>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof usersMePaymentDetailsRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUsersMePaymentDetailsRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



