import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { ROUTES } from '@/constants';

function getPageNameFromPath(path: string): string {
  for (const [key, value] of Object.entries(ROUTES)) {
    if (matchPath(value, path)) {
      return key
        .split('_')
        .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
        .join(' ');
    }
  }
  return 'Unknown Page';
}

export const MixpanelPageTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const pageName = getPageNameFromPath(location.pathname);
    mixpanel.track(`Viewed ${pageName}`, {
      path: location.pathname,
      search: location.search,
      url: window.location.href,
    });
  }, [location]);

  return null;
};
