import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { Crisp } from 'crisp-sdk-web';
import { Router } from './Router';
import { theme } from './theme';
import { AuthProvider } from './auth.context';
import { UserDataProvider } from '@/providers/userData';

Sentry.init({
  dsn: 'https://6dcbd16ca2143fa76c0af9e832b6deb5@o4504703140036608.ingest.us.sentry.io/4505032759312384',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

mixpanel.init('0ec4b0a992febe01828c978ab094d0f0', {
  track_pageview: false,
  persistence: 'localStorage',
});

Crisp.configure('6993e446-0bb7-4df2-a6c8-61169b010a1b');

const queryClient = new QueryClient();

export default function App() {
  return (
    <MantineProvider theme={theme}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <UserDataProvider>
            <Notifications position="top-right" zIndex={1000} />
            <ModalsProvider>
              <Router />
            </ModalsProvider>
          </UserDataProvider>
        </QueryClientProvider>
      </AuthProvider>
    </MantineProvider>
  );
}
