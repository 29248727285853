import React, { ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './auth.context';
import { ROUTES } from './constants';

interface ProtectedRouteProps {
  children: ReactNode;
}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      const searchParams = new URLSearchParams(location.search);
      const refCode = searchParams.get('ref');
      if (refCode) {
        localStorage.setItem('referralCode', refCode);
      }
    }
  }, [isAuthenticated, location.search]);

  if (isLoading) {
    // You can return a loading spinner or some other loading indicator here
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    console.log('not authenticated, redirecting to access page');
    return <Navigate to={ROUTES.ACCESS} state={{ from: location }} replace />;
  }

  return <>{children}</>;
}
