import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { decodeJwt, JWTPayload } from 'jose';
import mixpanel from 'mixpanel-browser';
import { AXIOS_INSTANCE, logout, refreshTokens } from './services/direct66/mutator/custom-instance';
import { config } from '@/config';

type Dispatch = (access: string, refresh: string) => void;
type AuthProviderProps = {
  children: ReactNode;
  initialState?: { access: string | null; refresh: string | null };
};

interface CustomJWTPayload extends JWTPayload {
  user_id?: string;
}

interface AuthContextType {
  accessToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  refreshAuthToken: () => Promise<boolean>;
}

const AuthContext = createContext<AuthContextType | null>(null);
const AuthDispatchContext = createContext<Dispatch | null>(null);

const AuthProvider = ({
  children,
  initialState = { access: null, refresh: null },
}: AuthProviderProps) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    () => initialState.access || localStorage.getItem('accessToken')
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    () => initialState.refresh || localStorage.getItem('refreshToken')
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);


  useEffect(() => {
    if (
      accessToken &&
      refreshToken &&
      window.chrome &&
      chrome.runtime &&
      chrome.runtime.sendMessage
    ) {
      try {
        chrome.runtime.sendMessage(
          config.CHROME_EXTENSION_ID,
          { type: 'SAVE_TOKENS', accessToken, refreshToken },
          (response) => {
            if (chrome.runtime.lastError) {
              console.error('Error sending tokens to extension:', chrome.runtime.lastError);
            } else {
              console.log('Tokens sent to extension, response:', response);
            }
          }
        );
      } catch (error) {
        console.error('Failed to send tokens to extension:', error);
      }
    } else {
      console.log(
        'Unable to save tokens: Chrome runtime, sendMessage function, or tokens not available'
      );
    }
  }, [accessToken, refreshToken]);

  const isTokenValid = useCallback((token: string | null): boolean => {
    if (!token) return false;
    try {
      const payload = decodeJwt(token) as CustomJWTPayload;
      if (payload.user_id) {
        mixpanel.identify(payload.user_id);
      }
      const currentTime = Math.floor(Date.now() / 1000);
      return (payload.exp as number) > currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return false;
    }
  }, []);

  const refreshAuthToken = useCallback(async (): Promise<boolean> => {
    try {
      const { accessToken: newAccessToken, refreshToken: newRefreshToken } = await refreshTokens();
      setAccessToken(newAccessToken);
      setRefreshToken(newRefreshToken);
      localStorage.setItem('accessToken', newAccessToken);
      localStorage.setItem('refreshToken', newRefreshToken);
      setIsAuthenticated(true);
      return true;
    } catch (error) {
      console.error('Failed to refresh token:', error);
      setAccessToken(null);
      setRefreshToken(null);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      setIsAuthenticated(false);
      await logout();
      return false;
    }
  }, []);

  useEffect(() => {
    const checkTokenValidity = async () => {
      setIsLoading(true);
      if (accessToken && !isTokenValid(accessToken)) {
        await refreshAuthToken();
      } else {
        setIsAuthenticated(!!accessToken);
      }
      setIsLoading(false);
    };
    checkTokenValidity();
  }, [accessToken, isTokenValid, refreshAuthToken]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'accessToken' || event.key === 'refreshToken') {
        if (!event.newValue) {
          setAccessToken(null);
          setRefreshToken(null);
          setIsAuthenticated(false);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const authContextValue = useMemo(
    () => ({
      accessToken,
      refreshToken,
      isAuthenticated,
      isLoading,
      refreshAuthToken,
    }),
    [accessToken, refreshToken, isAuthenticated, isLoading, refreshAuthToken]
  );

  const setTokens = useCallback((newAccessToken: string, newRefreshToken: string) => {
    setAccessToken(newAccessToken);
    setRefreshToken(newRefreshToken);
    localStorage.setItem('accessToken', newAccessToken);
    localStorage.setItem('refreshToken', newRefreshToken);
    setIsAuthenticated(true);
  }, []);

  return (
    <AuthContext.Provider value={authContextValue}>
      <AuthDispatchContext.Provider value={setTokens}>{children}</AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const useAuthDispatch = (): Dispatch => {
  const context = useContext<Dispatch | null>(AuthDispatchContext);
  if (context === null) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth, useAuthDispatch };
