import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { Crisp } from 'crisp-sdk-web';
import mixpanel from 'mixpanel-browser';
import { useUsersMeRetrieve } from '@/services/direct66/users/users';
import { User } from '@/services/direct66/models/user';
import { useAuth } from '../auth.context';

// Update UserData to match the User type from your API, making all properties optional
type UserData = {
  [K in keyof User]?: User[K];
};

interface UserDataContextType {
  userData: UserData | null;
  isUserDataLoading: boolean;
}

const UserDataContext = createContext<UserDataContextType | undefined>(undefined);

interface UserDataProviderProps {
  children: ReactNode;
}

export const UserDataProvider: React.FC<UserDataProviderProps> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const { isAuthenticated } = useAuth();

  const { data: fetchedUserData, isLoading: isUserDataLoading } = useUsersMeRetrieve({
    query: { enabled: isAuthenticated },
  });

  useEffect(() => {
    if (isAuthenticated && fetchedUserData) {
      setUserData(fetchedUserData);

      // Check if email and first_name exist before using them
      if (fetchedUserData.email && fetchedUserData.first_name) {
        // Configure Mixpanel
        mixpanel.people.set({
          $name: `${fetchedUserData.first_name} ${fetchedUserData.last_name || ''}`,
          $email: fetchedUserData.email,
        });

        // Configure Crisp
        Crisp.user.setEmail(fetchedUserData.email);
        Crisp.user.setNickname(fetchedUserData.first_name);
      }
    }
  }, [isAuthenticated, fetchedUserData]);

  const value: UserDataContextType = {
    userData,
    isUserDataLoading,
  };

  return <UserDataContext.Provider value={value}>{children}</UserDataContext.Provider>;
};

export const useUserData = (): UserDataContextType => {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error('useUserData must be used within a UserDataProvider');
  }
  return context;
};
