interface Config {
  ENVIRONMENT: string;
  API_BASE_URL: string;
  CHROME_EXTENSION_ID: string;
}

export const config: Config = {
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
  ENVIRONMENT: import.meta.env.MODE,
  CHROME_EXTENSION_ID: import.meta.env.VITE_CHROME_EXTENSION_ID,
};
