import React, { useState, useCallback } from 'react';
import {
  Table,
  Container,
  Text,
  Paper,
  Group,
  Avatar,
  Button,
  ActionIcon,
  TextInput,
  NumberInput,
  Modal,
  Badge,
  Stack,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconPlus, IconMinus, IconCoin } from '@tabler/icons-react';
import { useDebouncedCallback } from '@mantine/hooks';
import {
  useCustomersList,
  useCustomersByEmailWalletUpdatePointsCreate,
} from '@/services/direct66/customers/customers';
import { Customer } from '@/services/direct66/models';

const CustomerData: React.FC = () => {
  const { data: customersData, isLoading, refetch } = useCustomersList({ include_metadata: true });
  const updatePoints = useCustomersByEmailWalletUpdatePointsCreate();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [spendPoints, setSpendPoints] = useState<number | ''>('');
  const [spendNote, setSpendNote] = useState('');
  const [pendingUpdates, setPendingUpdates] = useState<{ [email: string]: number }>({});

  const debouncedUpdatePoints = useDebouncedCallback(async (email: string) => {
    const change = pendingUpdates[email];
    if (change === 0) return;

    const type = change > 0 ? 'GIVE' : 'TAKE';
    try {
      await updatePoints.mutateAsync({
        email,
        data: { points: Math.abs(change), type },
      });
      await refetch();
      notifications.show({
        title: 'Points Updated',
        message: `Successfully ${type === 'GIVE' ? 'added' : 'removed'} ${Math.abs(change)} points.`,
        color: 'green',
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to update points. Please try again.',
        color: 'red',
      });
    } finally {
      setPendingUpdates((prev) => ({ ...prev, [email]: 0 }));
    }
  }, 1000);

  const handlePointsChange = useCallback(
    (email: string, change: number) => {
      setPendingUpdates((prev) => ({
        ...prev,
        [email]: (prev[email] || 0) + change,
      }));
      debouncedUpdatePoints(email);
    },
    [debouncedUpdatePoints]
  );

  const handleSpendPointsChange = (value: string | number) => {
    setSpendPoints(value === '' ? '' : Number(value));
  };

  const handleSpendPoints = async () => {
    if (!selectedCustomer || spendPoints === '') return;

    try {
      await updatePoints.mutateAsync({
        email: selectedCustomer.email,
        data: { points: Number(spendPoints), type: 'SPEND', note: spendNote },
      });
      await refetch();
      setSelectedCustomer(null);
      setSpendPoints('');
      setSpendNote('');
      notifications.show({
        title: 'Points Spent',
        message: `Successfully spent ${spendPoints} points.`,
        color: 'green',
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to spend points. Please try again.',
        color: 'red',
      });
    }
  };

  const rows = customersData?.map((customer) => {
    const pendingChange = pendingUpdates[customer.email] || 0;
    const displayedPoints = customer.loyalty_points + pendingChange;

    return (
      <Table.Tr key={customer.id}>
        <Table.Td>
          <Group>
            <Avatar color="indigo" radius="xl">
              {customer.full_name.charAt(0)}
            </Avatar>
            <div>
              <Text size="sm" fw={500}>
                {customer.full_name}
              </Text>
              <Text size="xs" c="dimmed">
                {customer.email}
              </Text>
            </div>
          </Group>
        </Table.Td>
        <Table.Td>
          {customer.metadata.length > 0 ? (
            <Group gap="xs">
              {customer.metadata.map((meta) => (
                <Badge variant="outline" key={meta.id} color="blue">
                  {meta.field_name}: {meta.value}
                </Badge>
              ))}
            </Group>
          ) : (
            <Text size="sm" c="dimmed">
              No data
            </Text>
          )}
        </Table.Td>
      </Table.Tr>
    );
  });

  if (isLoading) {
    return <Text>Loading customers...</Text>;
  }

  return (
    <Container size="xl" mt={48}>
      <Paper withBorder p="md">
        <Table verticalSpacing="sm" highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Customer</Table.Th>
              <Table.Th>Data</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Paper>

      <Modal
        opened={!!selectedCustomer}
        onClose={() => setSelectedCustomer(null)}
        title="Spend Loyalty Points"
      >
        {selectedCustomer && (
          <Stack>
            <Text>Current Points: {selectedCustomer.loyalty_points}</Text>
            <NumberInput
              label="Points to Spend"
              value={spendPoints}
              onChange={handleSpendPointsChange}
              max={selectedCustomer.loyalty_points}
              min={0}
              clampBehavior="strict"
            />
            <TextInput
              label="Note"
              value={spendNote}
              onChange={(event) => setSpendNote(event.currentTarget.value)}
            />
            <Button onClick={handleSpendPoints}>Spend Points</Button>
          </Stack>
        )}
      </Modal>
    </Container>
  );
};

export default CustomerData;
