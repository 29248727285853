/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Your Project API
 * Your project description
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreateCustomerLoyaltyWallet,
  Customer,
  CustomersListParams,
  LoyaltyWallet,
  UpdatePoints
} from '.././models'
import { customInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';



/**
 * List customers with optional metadata
 */
export const customersList = (
    params?: CustomersListParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Customer[]>(
      {url: `/customers/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getCustomersListQueryKey = (params?: CustomersListParams,) => {
    return [`/customers/`, ...(params ? [params]: [])] as const;
    }

    
export const getCustomersListQueryOptions = <TData = Awaited<ReturnType<typeof customersList>>, TError = ErrorType<unknown>>(params?: CustomersListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customersList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomersListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof customersList>>> = ({ signal }) => customersList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof customersList>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomersListQueryResult = NonNullable<Awaited<ReturnType<typeof customersList>>>
export type CustomersListQueryError = ErrorType<unknown>

export const useCustomersList = <TData = Awaited<ReturnType<typeof customersList>>, TError = ErrorType<unknown>>(
 params?: CustomersListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customersList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomersListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Retrieve or create a loyalty wallet for a customer
 */
export const customersByEmailWalletRetrieve = (
    email: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<LoyaltyWallet>(
      {url: `/customers/by-email/${email}/wallet/`, method: 'GET', signal
    },
      );
    }
  

export const getCustomersByEmailWalletRetrieveQueryKey = (email: string,) => {
    return [`/customers/by-email/${email}/wallet/`] as const;
    }

    
export const getCustomersByEmailWalletRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof customersByEmailWalletRetrieve>>, TError = ErrorType<unknown>>(email: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customersByEmailWalletRetrieve>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomersByEmailWalletRetrieveQueryKey(email);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof customersByEmailWalletRetrieve>>> = ({ signal }) => customersByEmailWalletRetrieve(email, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(email), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof customersByEmailWalletRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomersByEmailWalletRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof customersByEmailWalletRetrieve>>>
export type CustomersByEmailWalletRetrieveQueryError = ErrorType<unknown>

export const useCustomersByEmailWalletRetrieve = <TData = Awaited<ReturnType<typeof customersByEmailWalletRetrieve>>, TError = ErrorType<unknown>>(
 email: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customersByEmailWalletRetrieve>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomersByEmailWalletRetrieveQueryOptions(email,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a loyalty wallet for a customer
 */
export const customersByEmailWalletCreate = (
    email: string,
    createCustomerLoyaltyWallet: CreateCustomerLoyaltyWallet,
 ) => {
      
      
      return customInstance<LoyaltyWallet>(
      {url: `/customers/by-email/${email}/wallet/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createCustomerLoyaltyWallet
    },
      );
    }
  


export const getCustomersByEmailWalletCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof customersByEmailWalletCreate>>, TError,{email: string;data: CreateCustomerLoyaltyWallet}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof customersByEmailWalletCreate>>, TError,{email: string;data: CreateCustomerLoyaltyWallet}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof customersByEmailWalletCreate>>, {email: string;data: CreateCustomerLoyaltyWallet}> = (props) => {
          const {email,data} = props ?? {};

          return  customersByEmailWalletCreate(email,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CustomersByEmailWalletCreateMutationResult = NonNullable<Awaited<ReturnType<typeof customersByEmailWalletCreate>>>
    export type CustomersByEmailWalletCreateMutationBody = CreateCustomerLoyaltyWallet
    export type CustomersByEmailWalletCreateMutationError = ErrorType<unknown>

    export const useCustomersByEmailWalletCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof customersByEmailWalletCreate>>, TError,{email: string;data: CreateCustomerLoyaltyWallet}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof customersByEmailWalletCreate>>,
        TError,
        {email: string;data: CreateCustomerLoyaltyWallet},
        TContext
      > => {

      const mutationOptions = getCustomersByEmailWalletCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update points for a customer's loyalty wallet
 */
export const customersByEmailWalletUpdatePointsCreate = (
    email: string,
    updatePoints: UpdatePoints,
 ) => {
      
      
      return customInstance<LoyaltyWallet>(
      {url: `/customers/by-email/${email}/wallet/update-points/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updatePoints
    },
      );
    }
  


export const getCustomersByEmailWalletUpdatePointsCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof customersByEmailWalletUpdatePointsCreate>>, TError,{email: string;data: UpdatePoints}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof customersByEmailWalletUpdatePointsCreate>>, TError,{email: string;data: UpdatePoints}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof customersByEmailWalletUpdatePointsCreate>>, {email: string;data: UpdatePoints}> = (props) => {
          const {email,data} = props ?? {};

          return  customersByEmailWalletUpdatePointsCreate(email,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CustomersByEmailWalletUpdatePointsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof customersByEmailWalletUpdatePointsCreate>>>
    export type CustomersByEmailWalletUpdatePointsCreateMutationBody = UpdatePoints
    export type CustomersByEmailWalletUpdatePointsCreateMutationError = ErrorType<unknown>

    export const useCustomersByEmailWalletUpdatePointsCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof customersByEmailWalletUpdatePointsCreate>>, TError,{email: string;data: UpdatePoints}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof customersByEmailWalletUpdatePointsCreate>>,
        TError,
        {email: string;data: UpdatePoints},
        TContext
      > => {

      const mutationOptions = getCustomersByEmailWalletUpdatePointsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const customersSearchList = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<Customer[]>(
      {url: `/customers/search/`, method: 'GET', signal
    },
      );
    }
  

export const getCustomersSearchListQueryKey = () => {
    return [`/customers/search/`] as const;
    }

    
export const getCustomersSearchListQueryOptions = <TData = Awaited<ReturnType<typeof customersSearchList>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customersSearchList>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomersSearchListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof customersSearchList>>> = ({ signal }) => customersSearchList(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof customersSearchList>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomersSearchListQueryResult = NonNullable<Awaited<ReturnType<typeof customersSearchList>>>
export type CustomersSearchListQueryError = ErrorType<unknown>

export const useCustomersSearchList = <TData = Awaited<ReturnType<typeof customersSearchList>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customersSearchList>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomersSearchListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



